<template>
    <div class="profile narrow-form">
        <h1 class="my-5">Create User</h1>

        <data-form :field-model="userModel"
                   :on-submit-handler="createUser"
                   :field-groups="fieldGroups"
                   cancel-route="/users"
                   bottom-submit-button bottom-cancel-button/>
    </div>
</template>

<script>
import User from '@/models/User'
import SpinnerUntil from "../components/SpinnerUntil";
import Utils from "@/providers/Utils";

export default {
    name: 'UserCreate',
    components: { SpinnerUntil },
    props: {
        userId: String
    },
    data () {
        return {
            user: {},
            fieldGroups: [
                ['email', 'password', 'firstName', 'lastName', 'admin'],
            ],
        }
    },
    computed: {
        userModel() {
            let model = User
            model.fields.email.descriptionKey = 'PROFILE_EMAIL_CREATE'
            return model
        },
        userLoggedIn() {
            return this.$api.userLoggedIn()
        },
    },
    methods: {
        async loadModelInstance () {
            this.user = {}
            return this.user
        },
        async createUser (modelInstance) {
            console.log('UserCreate:createUser')
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])

            try {
                // create user, send verification email, create DB record
                const userId = await this.$api.createUser (this, modelInstance.email, modelInstance.password)
                console.log('UserCreate:createUser  userId=', userId)
                // create user record
                const attrs = {
                    email: modelInstance.email,
                    emailReadonly: modelInstance.email,
                    firstName: modelInstance.firstName,
                    lastName: modelInstance.lastName,
                    admin: modelInstance.admin
                }
                console.log('UserCreate:createUser  attrs=', attrs)

                await this.$api.setItem(this, `users`, userId, attrs)
                // must sign out
                this.$api.logoutUser(this)
                this.$toasted.success(this.$i18n.gt('USER_CREATE_SUCCESS'))
            } catch (error) {
                console.error('UserCreate:createUser  error=', error)
                if (error.code === 'auth/email-already-in-use') {
                    this.$toasted.error('Email already in use')
                } else {
                    this.$toasted.error('Unknown email encountered: ' + error.code)
                }
            }
        }
    }
}
</script>

<style lang="scss">

</style>

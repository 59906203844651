<template>
    <div class="cards-view">
        <card-actions :loc-id="locId" :card-id="cardId" @card-changed="loadNewCard"/>
        <spinner-until :condition="cardLoaded">
            <b-row class="m-3 mt-4 justify-content-between" v-if="$mq === 'xl' || $mq === 'lg'">
                <h1>{{ currentCard.name }}</h1>
                <div>
                    <b-btn variant="secondary" size="sm" class=" ml-2 btn-icon"
                           @click="navigate(`/locations/${locId}/cards/${cardId}/edit`)"
                           v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                        <b-icon-pencil/>
                        {{ $i18n.gt('CARDS_EDIT_HEADING') }}
                    </b-btn>
                </div>
            </b-row>
            <hr>

            <b-row class="cards-view__content">
                <b-col class="col-12 col-md-6">
                    <b-card class="mb-3">

                            <strong>{{ $i18n.gt('CARD_VIEW_PESTS') }}</strong>
                            <p>
                                <template v-for="(pest, i) in currentPests">
                                    {{ pest.name }}<template v-if="i+1 !== currentPests.length">, </template>
                                </template>
                            </p>
                            <strong>{{ $i18n.gt('CARD_VIEW_DISEASES') }}</strong>
                            <p>
                                <template v-for="(disease, i) in currentDiseases">
                                    {{ disease.name }}<template v-if="i+1 !== currentDiseases.length">, </template>
                                </template>
                            </p>

                            <strong>{{ $i18n.gt('CARD_VIEW_BENEFICIALS') }}</strong>
                            <p>
                                <template v-for="(beneficial, i) in currentBeneficials">
                                    {{ beneficial.name }}<template v-if="i+1 !== currentBeneficials.length">, </template>
                                </template>
                            </p>
                            <strong>{{ $i18n.gt('CARD_NOTES_FIELD') }}</strong>
                            <p>{{ currentCard.notes }}</p>

                    </b-card>

                </b-col>

                <b-col class="col-12 col-md-6 mb-3 mb-lg-0 cards-view__qr-container">

                    <canvas id="qrcode" ></canvas>

                    <b-btn variant="secondary" class="print-qr-button" @click="print()" v-if="!isNative">
                        <b-icon-printer/>
                        {{ $i18n.gt('PRINT') }}
                    </b-btn>

                </b-col>

            </b-row>

            </spinner-until>

            <div class="print-container">
                <canvas id="print-qrcode"/>
                <b-badge variant="secondary">
                    <b-icon-house-door-fill></b-icon-house-door-fill>
                    {{ currentLocation.name }}
                </b-badge>
                <h1>{{ currentCard.name }}</h1>
            </div>

    </div>

</template>

<script>

import CardFields from '../models/Card'
import QRious from 'qrious'
import Vue from 'vue'
import {Capacitor} from "@capacitor/core";

export default {
    name: 'CardsView',
    props: {
        locId: String,
        cardId: String,
    },
    data() {
        return {
            cardLoaded: false,
            selectedCard: '',
            currentCard: {},
            currentPests: [],
            currentDiseases: [],
            currentBeneficials: [],
            currentLocation: {},
            cardModel: CardFields,
            fieldGroups: [
                ['name', 'notes'],
            ],
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        },
        isNative() {
            return Capacitor.isNativePlatform()
        },
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getCard(locId, cardId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/${locId}/cards`, cardId)
        },
        getLocation(locId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/`, locId)
        },
        async getReferences(references) {
            if (!references) return null
            const items = []
            // regex to split after last '/'
            const pattern = /\/(?!.*\/)/
            references.forEach(async (ref) => {
                const segments = ref.split(pattern)
                items.push(await this.$api.getItem(this, segments[0], segments[1]))
            })
            return items
        },
        async loadCard(location, card) {
            this.currentCard = await this.getCard(location, card)
            this.currentPests = await this.getReferences(this.currentCard.pests)
            this.currentDiseases = await this.getReferences(this.currentCard.diseases)
            this.currentBeneficials = await this.getReferences(this.currentCard.beneficials)
        },
        async loadNewCard(path) {
            const segments = path.split('/')
            this.cardLoaded = false
            await this.loadCard(segments[2], segments[4])
            this.currentLocation = await this.getLocation(segments[2])
            this.cardLoaded = true
            // change the current route to reflect new card, regenerate qr code
            Vue.nextTick(() => {
                this.generateQR()
            })
        },
        generateQR() {
            let host = ''
            const env = this.$api.db_env
            if (env === 'greenhouse_prod') {
                host = 'https://scout.pocketipm.com'
            } else if (env === 'greenhouse_staging') {
                host = 'https://greenhouse-scout.web.app'
            } else {
                host = 'localhost:8012'
            }
            new QRious({
                level: 'H',
                element: document.getElementById('qrcode'),
                size: 700,
                value: host + window.location.pathname
            });
            new QRious({
                level: 'H',
                element: document.getElementById('print-qrcode'),
                size: 500,
                value: host + window.location.pathname
            });
        },
        print() {
            window.print()
        },
    },
    async mounted() {
        await this.loadCard(this.locId, this.cardId)
        this.currentLocation = await this.getLocation(this.locId)
        this.selectedCard = `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`
        this.cardLoaded = true
        Vue.nextTick(() => {
            this.generateQR()
        })
    }
}
</script>

<style lang="scss">

.cards-view {
    > .spinner-until {
        max-width: 1200px!important;
        margin:0 auto;
    }

    .card-top-row {
        padding:20px 0;
        @media (max-width:767px) {

            .badge-success {
                margin-bottom: 1em;
            }
            select {
                margin-bottom: .5em;
                width:100%;
            }
            .btn {
                margin-top:.5em;
            }
        }

        @media (min-width:768px) {
            display:flex;
            justify-content: space-between;
            flex-wrap:wrap;

            .badge-success {
                margin-bottom: 1em;
            }
            > * {
                //margin: 15px 0;
            }
            .card-actions {
                width:100%;
            }

        }

        @media (min-width:1024px) {
            .card-actions {
                width:auto;
            }
        }

        :not(:last-child).btn-success {
            padding: 8px;
            font-size: 14px;
            margin-right: 5px;
        }

        .badge-success {
            display: inline-flex;
            color: #076A1A;
            font-size: 16px;
            padding: 10px 26px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            background-color: #90F1A3;
            border-radius: 12px;
            height: 100%;

        }
    }

    select {
        width: auto;
    }
    .card-body {

    }
    .btn-secondary.print-qr-button {
        //border: none;
        @media (min-width:652px) {
            position: absolute;
            margin: 0 0 15px 15px;
        }
    }
    .print-container {
        display: none;
    }

    #qrcode {
        box-shadow: 3px 3px 20px rgba(60, 150, 78, 0.25);
        max-width: 100%;
        @media (min-width:652px) {
            //width:70%;
            //max-width:500px;
        }
    }

}

.cards-view__qr-container {
    padding-right:140px;
}

@media print {
    div.side-menu, div.side-menu.side-menu-expanded, header.header, div.main-footer, .cards-view .spinner-until * {
        display: none !important;
    }
    .cards-view .print-container {
        max-height: 700px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        > * {
            margin-top: 25px;
        }
        span.badge-secondary {
            border-radius: 12px;
            border: 4px solid #465046;
            background-color: #D9D9D9;
            color: #465046;
            display: flex;
            font-size: 16px;
            padding: 10px 26px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
        }
    }
}

@media (max-width:980px) {
    .cards-view__content {
        flex-direction: column-reverse;
    }
}

</style>
